import React, {Component} from 'react';
import { Header } from 'react-native-elements';
import { FontAwesome, Entypo } from '@expo/vector-icons';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import Card from './Cards';
import Services from './Services';
import firebase from 'firebase';
import AsyncStorage from '@react-native-community/async-storage';
import Carousel from './Carousel';
import ConsultationList from './ConsultationList';

class HomeScreen extends Component {
    constructor(props){
        super(props)
        this.state = {
          user: null,
        }
    }

    componentDidMount() {
           AsyncStorage.getItem('number').then((result)=> {
             this.setState({user: result})
           });

     }

  render() {

    return (
        <View style={{ backgroundColor: 'none', marginBottom: 40}}>
            <Header placement="left" backgroundColor='none'
            leftComponent={<FontAwesome.Button title='' backgroundColor='none' onPress={() => this.props.navigation.openDrawer()}>
            <Entypo name="menu" size={24} color="black" />
            </FontAwesome.Button>}
           
            rightComponent={<View  style={{ alignItems: "flex-end", flex: 1 }}>
            <Image
                style={{ height: 35, width: 45 }}
                source={require("../assets/logo_dc.png")}></Image>
          </View>}
            />
            <Card></Card>
            <Services ganda={this.props.navigation}></Services>
            <Carousel></Carousel>
            {this.state.user != null && <ConsultationList ganda={this.props.navigation}></ConsultationList>}
        </View>
    );
  }
}
export default HomeScreen;

const styles = StyleSheet.create({
    login: {
        backgroundColor: 'transparent',
        display: 'block',
        backgroundImage: 'none',
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: 12,
        paddingLeft: 12,
        marginBottom:20,
        borderRadius: 3,
        borderStyle: 'solid',
        borderColor: '#14bef0',
        color: '#14bef0',
        borderWidth: 1,
        fontFamily: 'Nunito',
        fontSize: 13
    }
});