var React = require('react-native');

var {
  StyleSheet
} = React;

module.exports = StyleSheet.create({

fontFamily: {
    fontFamily: 'Nunito',
    marginTop: 15
}
});